import { Link } from "react-router-dom";

import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';

import goBack from './../../images/go-back.svg';

let members = [
  {
    name: 'Tatiana Andreeva',
    title: 'Law',
    contry: 'Russia',
    introduce: 'Lawyer for construction, art, corporates and specialist in international litigation.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/tatiana-a-9341a619b'
  },
  {
    name: 'Dr Tamanna Khosla',
    title: 'Ethics',
    contry: 'India',
    introduce: 'Author of three books in political theory, contemporary ethics, comparative religions and feminism.',
    avatarType: 'jpg',
    link:'https://linkedin.com/in/dr-tamanna-khosla-8485643'
  },
  {
    name: 'Raili Maripuu',
    title: 'Mobile Data',
    contry: 'Estonia',
    introduce: 'CEO of Mobilewatch and Whiterock Privacy - 15 years of data innovation in IP, corporate security, compliance, and data regulations.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/raili-maripuu-705262126'
  },
  {
    name: 'Roland Min',
    title: 'Tech',
    contry: 'UK',
    introduce: 'CEO Six Dimensions and BDMI Tech Lead.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/roland-min-4a421b107'
  },
  {
    name: 'Edouard Lansiaux',
    title: 'GenZ',
    contry: 'France',
    introduce: 'CEO of Human Value Stock Exchange.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/edouard-lansiaux-14381a151'
  },
  {
    name: 'Dr Galit Wellner',
    title: 'Philosopher of digital technologies',
    contry: 'Israel',
    introduce: 'Techno and software philosopher.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/galitwellner'
  },
  {
    name: 'Dr Cheryl Yu',
    title: 'Education ',
    contry: 'China',
    introduce: 'International digital education researcher.',
    avatarType: 'jpg',
    link:'https://www.uca.ac.uk/about-us/our-staff/cheryl-yu/'
  },
  {
    name: 'Dr Leo Wang',
    title: '4IR Values Tech ',
    contry: 'UK',
    introduce: 'Frontiers in Blockchain Guest Associate Editor.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/dr-yonghao-wang-8790b4196/'
  },
]

function OT() {
  return (
    <main>
      <Container>
        <Link to="/media" className="d-flex align-items-center page-title blod">
          <img src={goBack} alt="go back"/> Advisory Team
        </Link>
        <div className="page-content">
          <Row>
            {
              members.map((member, index)=>
                <Col md="6" xl="4" key={index}>
                  <div className="member-info bd">
                    <div className="member-info-inner text-center">
                      <div className="member-avatar">
                        <img src={'/images/photos/O'+(index+1) + '. '+member.name+'.'+member.avatarType} alt={member.name} className="member-img"  />
                        <img src={'/images/nf/' + member.contry.toLowerCase() + '/oval.png'} alt={member.contry} className="member-contry" />
                      </div>
                      <div className="name">{member.name}</div>
                      <div className="title">{member.title}</div>
                      <div className="introduce">{member.introduce}</div>
                      <div className="text-end mt-4">
                        <a href={member.link} target="_blank" className="member-link" rel="noreferrer">MORE</a>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
      </Container>
    </main>
  );

}

export default OT