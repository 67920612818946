import { Link } from "react-router-dom";

import { 
  Container, 
  Row,
  Col
} from 'react-bootstrap';

import nextImage from './../../images/next.svg';
import previousImage from './../../images/previous.svg';

function Ideology() {
  return (
    <main>
      <Container>
        <Row className="justify-content-center">
          <Col xl="10">
            <h1 className="page-title">Our <span className="page-title-large">Ideology</span></h1>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-start">
          <Link to='/data' className="page-control-left"><img src={previousImage} alt="previous page"/></Link>
          <Col xl="8" sm="10" xs="9" className="page-content">
            <p>Our central thesis is that in a world of 8 billion population with 8 billion unique sets of values, the world we want to help create is where every opinion has a voice and right of expression. Nevertheless, we wish to grow this within a socially responsible ecosystem where media companies, including social media, not only allows for individual ideology to be shared, but also protects people from the harm of other ideologies not aligned to their own. This is indeed a difficult balance.</p>
            <p>The benefits of such a world would be less fake news, more protection of individual data rights, but more importantly the resolution of conflict not through partisan violence and online threats but celebrating diversity through a clash of passionately held truths. Whether our data is used to sell us more in exchange for free platforms, or used to safeguard us by governments, this is a global conundrum which we have dedicated ourselves to. </p>
            <p>The presence of legacy systems hampers our progress and we believe that the metaverse represents an opportunity to press reset, reinvent and rehearse an influencial media ecosystem where we nudge each other to great values, greater good and a greater world. A world where our soft intangible non-financial values are as important as our hard tangible financial assets and value. </p>
            <p>Is metaverse a Nirvana of real life or an extension of real-life? How can we use metaverse to help battle the fake news and exploitation of  the user data.</p>
            <p>Is there a deep connection between the lack and weakening of authority of media companies and the misuse of personal data by platforms</p>
            <p>BMDI is a spin-out from the Centre for Citizenship, Enterprise and Governance who, for over a decade, have consulted to many global brands and governments. Curation for BMDI has been facilitated by <a href="https://www.bdmt.io/" target="_blank" rel="noreferrer">B&D Media Tech Inc</a> since 2018.</p>
          </Col>
          <Link to='/bureau' className="page-control-right"><img src={nextImage} alt="next page"/></Link>
        </div>
      </Container>
    </main>
  );

}

export default Ideology