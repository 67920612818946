import { Link } from "react-router-dom";

import { 
  Container
} from 'react-bootstrap';

import logo320 from './../../images/logo-320.svg';
import logo567 from './../../images/logo-576.svg';
import logo768 from './../../images/logo-768.svg';

function Home() {
  return (
    <main className="pb-5">
      <Container>
        <h1 className="home-page-title">Bureau of Media, Data and Ideology<br/><span>媒体、数据和价值体系研究院</span></h1>
        <div className="home-page-subtitle">New York - London - Taipei</div>
        <div className="home">
          <Link to='/bureau' className="menu-link bureau">Bureau</Link>
          <Link to='/media' className="menu-link media">Media</Link>
          <Link to='/data' className="menu-link data">Data</Link>
          <Link to='/ideology' className="menu-link ideology">Ideology</Link>
          <span className="home-title">BMDI</span>
          <picture>
            <source media="(min-width: 768px)" srcSet={logo768} />
            <source media="(min-width: 576px)" srcSet={logo567} />
            <img src={logo320} className="home-logo" alt="logo" />
          </picture>
        </div>
      </Container>
    </main>
  );
}

export default Home