import { Link } from "react-router-dom";

import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';

import goBack from './../../images/go-back.svg';

let sponsors = [
  {
    title: 'CCEG',
    description: 'Centre for Citizenship, Enterprise and Governance'
  },
  {
    title: '6DF',
    description: 'Six Dimetion Foundation<br/>DAO'
  },
]

function Sponsors() {
  return (
    <main>
      <Container>
        <Link to="/media" className="d-flex align-items-center page-title blod">
          <img src={goBack} alt="go back"/> Sponsors
        </Link>
        <div className="page-content">
          <Row>
            {
              sponsors.map((sponsor, index)=>
                <Col md="6" xl="4" key={index}>
                  <div className="member-info bd">
                    <div className="member-info-inner text-center">
                      <div className="member-avatar">
                        <img src={'/images/sponsors/'+sponsor.title+'.png'} alt={sponsor.title} className="member-img"  />
                      </div>
                      <div className="sponsor-title">{sponsor.title}</div>
                      <div className="sponsor-description" dangerouslySetInnerHTML={{ __html: sponsor.description}} />
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
      </Container>
    </main>
  );

}

export default Sponsors