import { Link } from "react-router-dom";

import { 
  Container, 
  Row,
  Col,
  Ratio
} from 'react-bootstrap';

import nextImage from './../../images/next.svg';
import previousImage from './../../images/previous.svg';

import twitterImage from './../../images/twitter.svg';
import serviceImage from './../../images/service.svg';

function Bureau() {
  return (
    <main>
      <Container>
        <Row className="justify-content-center">
          <Col xl="10">
            <h1 className="page-title">The <span className="page-title-large">Bureau</span></h1>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-start">
          <Link to='/ideology' className="page-control-left"><img src={previousImage} alt="previous page"/></Link>
          <Col xl="8" sm="10" xs="9" className="page-content">
            <p className="page-content-title">Bureau of Media, Data and Ideology</p>
            <p>A global Think Tank for media, data, individual rights and fake news.</p>
            <p>BMDI launches with well known internationally recognized social media brands and traditional household media outlets who wish to combat the increasing propensity of Fake News and misinformation by rewarding truthful and factual content. To do so, however, requires developing a media ecosystem that differentiates between legitimate ideology and opinion, and those who wish to mislead society. We are 100% non-partisan, neutral and apolitical.</p>
            
            <Ratio aspectRatio="16x9">
              <iframe 
                className="w-100 h-100 border-none"
                src="https://www.youtube.com/embed/vqaJV8mGsnc"
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen />
            </Ratio>
            <p/>
            <p>BMDI operates within a registered nonprofit 501(c) structure based in New York since 2020, and is a spin out from the world’s leading Think Tank on the Movement of Value, the UK based Centre for Citizenship, Enterprise and Governance operating since 2013 and now with over 200,000 members.</p>
            <p>
              Follow us on twitter<a href="https://twitter.com/thebmdi" target="_blank" className="ms-2" rel="noreferrer"><img src={twitterImage} alt="twitter"/></a><br/>
              Email us<a href="mailto:info@bmdi.solutions" target="_blank" className="ms-2" rel="noreferrer"><img src={serviceImage} alt="email"/></a>
            </p>
          </Col>
          <Link to='/media' className="page-control-right"><img src={nextImage} alt="next page"/></Link>
        </div>
      </Container>
    </main>
  );
}

export default Bureau