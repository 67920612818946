import { useEffect } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router";

import { 
  Container
} from 'react-bootstrap';

import './App.scss';
import logoImage from './logo.svg';
import bg320 from './images/bg-320.svg';
import bg576 from './images/bg-576.svg';
import bg768 from './images/bg-768.svg';

import Home from './pages/Home';
import Bureau from './pages/Bureau';
import Media from './pages/Media';
import Data from './pages/Data';
import Ideology from './pages/Ideology';
import BD from './pages/BD';
// import CA from './pages/CA';
import OT from './pages/OT';
import Sponsors from './pages/Sponsors';

function App() {
  const location = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="App">
        <header className="App-header">
          <Container>
            <nav className="header-nav d-flex">
              {
                location.pathname !== '/' && <Link to="/"><img className="logo" src={logoImage} alt="logo"/></Link>
              }
            </nav>
          </Container>
        </header>
        {
          (location.pathname === '/' ||
          location.pathname === '/bureau' ||
          location.pathname === '/media' ||
          location.pathname === '/data' ||
          location.pathname === '/ideology') &&
          <div className={'star ' + location.pathname.substring(1)}>
            {/* <div className="bg" style={{backgroundImage: 'url("'+bg+'")'}}></div> */}
            <picture>
              <source media="(min-width: 768px)" srcSet={bg768} />
              <source media="(min-width: 576px)" srcSet={bg576} />
              <img className="bg" src={bg320} alt="bg" />
            </picture>
          </div> 
        }
        <Routes>
          <Route path="/" element={<Home />} name="home" />
          <Route path="/bureau" element={<Bureau />} name="bureau" />
          <Route path="/media" element={<Media />} />
          <Route path="/data" element={<Data />} />
          <Route path="/ideology" element={<Ideology />} />
          <Route path="/board-profiles" element={<BD />} />
          {/*<Route path="/corporate-advisor" element={<CA />} />*/}
          <Route path="/advisory-team" element={<OT />} />
          <Route path="/sponsors" element={<Sponsors />} />
        </Routes>
        {/* <footer className="App-footer"> */}
        {/*   <Container> */}
        {/*     footer */}
        {/*   </Container> */}
        {/* </footer> */}
    </div>
  );
}

export default App;
