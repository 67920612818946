import { Link } from "react-router-dom";

import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';

import nextImage from './../../images/next.svg';
import previousImage from './../../images/previous.svg';

function Data() {
  return (
    <main>
      <Container>
        <Row className="justify-content-center">
          <Col xl="10">
            <h1 className="page-title"><span className="page-title-large">Data</span> driven</h1>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-start">
          <Link to='/media' className="page-control-left"><img src={previousImage} alt="previous page"/></Link>
          <Col xl="8" sm="10" xs="9" className="page-content">
            <p>In a data driven society, political governance is based on science and public opinion. Through Fourth Industrial Revolution Technologies of Blockchain, AI, 5G, IoT and Web3 we are devising systems to help the public to recognize their individual ‘truth’ through transparency, consensus and value. Addressing data manipulation and opinion deception in political and commercial activities, our Think Tank and media partners wish to provide citizens with better demand awareness tools for their security and privacy including data ownership rights, now and into the Metaverse.</p>

            <p className="page-content-title">Some of our Projects</p>
            <p>| Metaverse |<br/>Extension of real-life or Nirvana of the real life.</p>
            <p>| GamiFi |<br/>Using game theory to debate the facts of news.</p>
            <p>| SocialFi |<br/>Capture the intangible value of social relations and social missions.</p>
            <div>| DeepFake |<br/>Distinguishing between intention and consequence
              <br/>
              <br/>
              <ul>
                <li>NFT-Truth reward: Using GameFi to debate the facts of news and reward predicted outcomes</li>
                <li>Auto-Fact verification: AI algorithms that fact check a post with % verification</li>
                <li>KOL Bonds: SocialFi system to reward Key Opinion Leaders for their social impact</li>
                <li>Unique-ID Avatars: Capturing individual values through metaverse representation</li>
                <li>Governance spread-voting: Not just discrete voting, but capturing the spread of beliefs across an ideology</li>
                <li>Wiki-of-Opinion: Opinions captured in time, and contested and challenged supported by evidence</li>
                <li>Fake-Tag: Tagging of deliberate Fake-News and Deep-Fake content to notify viewers of legitimate entertainment or thought provoking rhetoric</li>
              </ul>
            </div>
          </Col>
          <Link to='/ideology' className="page-control-right"><img src={nextImage} alt="next page"/></Link>
        </div>
      </Container>
    </main>
  );
}

export default Data