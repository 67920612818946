import { Link } from "react-router-dom";

import { 
  Container, 
  Row,
  Col
} from 'react-bootstrap';

import goBack from './../../images/go-back.svg';

let members = [
  {
    name: 'Sabrina Cohen Dumani',
    title: 'Ecosystem',
    contry: 'Switzerland',
    introduce: 'CEO & founder of Nomads Foundation working with global partners in Geneva to develop impact ecosystems.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/sabrinacohendumani'
  },
  {
    name: 'Prof Ilan Alon',
    title: 'Blockchain',
    contry: 'Norway',
    introduce: 'Blockchain academic at Agder University specialising in NFT, tokenomics and cryptocurrencies. ',
    avatarType: 'jpg',
    link:'https://linkedin.com/in/ilanalon'
  },
  {
    name: 'Dr Lyat Friedman',
    title: 'Philosophy',
    contry: 'Israel',
    introduce: 'Academic philosopher specialising in social media and truth.',
    avatarType: 'jpg',
    link:'https://www.bezalel.ac.il/en/node/5626'
  },
  {
    name: 'Prof Wulf Kaal',
    title: 'Data',
    contry: 'USA',
    introduce: 'Lawyer, economist, and technology professor specialising in DAO and decentralization.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/wulf-kaal-phd-6904a65b'
  },
  {
    name: 'Dr Lizzi Lee',
    title: 'BMDI CEO',
    contry: 'USA',
    introduce: 'MIT economist and news media anchor.',
    avatarType: 'jpg',
    link:'https://thediplomat.com/authors/lizzi-c-lee/'
  },
  {
    name: 'Dr Jon Machtynger',
    title: 'Technology',
    contry: 'UK',
    introduce: 'Technology Strategist and Visiting Research Fellow',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/jon-machtynger-12b415'
  },
  {
    name: 'Preeti Malhotra',
    title: 'Corporate',
    contry: 'Singapore',
    introduce: 'Chair of Smart Bharat Group with interests in the mobility, finance, entertainment, electric vehicles and healthcare sectors.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/preeti-malhotra-3135b514/?originalSubdomain=in'
  },
  {
    name: 'Dr Ali Abu Sadra',
    title: 'Legislation',
    contry: 'Qatar',
    introduce: 'International legislation expert representing middle eastern governments.',
    avatarType: 'png',
    link:'https://www.eastmedenergysummit.com/dr-ali-abu-sedra'
  },
  {
    name: 'Prof Olinga Taeed',
    title: 'Chair',
    contry: 'UK',
    introduce: 'Founder of CCEG, the world’s leading think tank on the movement of value.',
    avatarType: 'jpg',
    link:'https://www.linkedin.com/in/olingataeed'
  },
]

function BD() {
  return (
    <main>
      <Container>
        <Link to="/media" className="d-flex align-items-center page-title blod">
          <img src={goBack} alt="go back"/> Board Profiles
        </Link>
        <div className="page-content">
          <Row>
            {
              members.map((member, index)=>
                <Col md="6" xl="4" key={index}>
                  <div className="member-info bd">
                    <div className="member-info-inner text-center">
                      <div className="member-avatar">
                        <img src={'/images/photos/B'+(index+1) + '. '+member.name+'.'+member.avatarType} alt={member.name} className="member-img"  />
                        <img src={'/images/nf/' + member.contry.toLowerCase() + '/oval.png'} alt={member.contry} className="member-contry" />
                      </div>
                      <div className="name">{member.name}</div>
                      <div className="title">{member.title}</div>
                      <div className="introduce">{member.introduce}</div>
                      <div className="text-end mt-4">
                        <a href={member.link} target="_blank" className="member-link" rel="noreferrer">MORE</a>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
      </Container>
    </main>
  );

}

export default BD