import { Link } from "react-router-dom";

import { 
  Container,
  Row,
  Col
} from 'react-bootstrap';

import nextImage from './../../images/next.svg';
import previousImage from './../../images/previous.svg';

import logo320 from './../../images/media-group-320.svg';
import logo567 from './../../images/media-group-576.svg';
import logo768 from './../../images/media-group-768.svg';
import logo992 from './../../images/media-group-992.svg';

function Media() {
  return (
    <main>
      <Container>
        <Row className="justify-content-center">
          <Col xl="10">
            <h1 className="page-title"><span className="page-title-large">Media</span> group</h1>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-start">
          <Link to='/bureau' className="page-control-left"><img src={previousImage} alt="bureau"/></Link>
          <Col xl="8" sm="10" xs="9" className="page-content">
            <div className="text-center">
              <div className="media-group-content">
                <picture>
                  <source media="(min-width: 992px)" srcSet={logo992} />
                  <source media="(min-width: 768px)" srcSet={logo768} />
                  <source media="(min-width: 576px)" srcSet={logo567} />
                  <img src={logo320} className="media-group-logo" alt="media-group-logo" />
                </picture>
                <div className="h-100 d-flex flex-column justify-content-between">
                  <Link to="/board-profiles"><span className="linls">Board Profiles</span></Link>
                  <Link to="/advisory-team"><span className="linls">Advisory Team</span></Link>
                  <Link to="/media"><span className="linls">Corporate Advisors</span><span className="subspan">TBA</span></Link>
                  <Link to="/sponsors"><span className="linls">Sponsors</span></Link>
                </div>

              </div>
            </div>
          </Col>
          <Link to='/data' className="page-control-right"><img src={nextImage} alt="data"/></Link>
        </div>
      </Container>
    </main>
  );

}

export default Media